import React, { FC } from 'react';

type Props = {
  additionalStyles?: { [index: string]: number | string };
  additionalClassNames?: string[];
};

const defaultProps: Props = {
  additionalStyles: {},
  additionalClassNames: [],
};

const IconAngleLeft: FC<Props> = ({ additionalStyles, additionalClassNames }: Props) => (
  <svg
    className={`jc-icon ${additionalClassNames && additionalClassNames.join(' ')}`}
    style={additionalStyles && additionalStyles}
    data-icon-id="angle-left"
    data-testid="angle-left"
    aria-hidden="true"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 320 512"
  >
    <path
      fill="currentColor"
      d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"
    />
  </svg>
);

IconAngleLeft.defaultProps = defaultProps;

export default IconAngleLeft;
