import React, { FC } from 'react';

type Props = {
  additionalStyles?: { [index: string]: number | string };
  additionalClassNames?: string[];
};

const defaultProps: Props = {
  additionalStyles: {},
  additionalClassNames: [],
};

const IconTwitter: FC<Props> = ({ additionalStyles, additionalClassNames }: Props) => (
  <svg
    className={`jc-icon ${additionalClassNames && additionalClassNames.join(' ')}`}
    style={additionalStyles && additionalStyles}
    data-icon-id="twitter"
    data-testid="twitter"
    aria-hidden="true"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 28 22.741"
  >
    <path
      fill="currentColor"
      transform="translate(0 -48.082)"
      d="M25.122,53.75c.018.249.018.5.018.746,0,7.586-5.774,16.327-16.327,16.327A16.217,16.217,0,0,1,0,68.247a11.871,11.871,0,0,0,1.386.071A11.493,11.493,0,0,0,8.51,65.866a5.749,5.749,0,0,1-5.366-3.98,7.237,7.237,0,0,0,1.084.089,6.069,6.069,0,0,0,1.51-.2,5.739,5.739,0,0,1-4.6-5.632v-.071a5.779,5.779,0,0,0,2.594.728A5.747,5.747,0,0,1,1.954,49.13a16.312,16.312,0,0,0,11.833,6.005,6.478,6.478,0,0,1-.142-1.315,5.744,5.744,0,0,1,9.932-3.926,11.3,11.3,0,0,0,3.642-1.386A5.723,5.723,0,0,1,24.7,51.671a11.5,11.5,0,0,0,3.3-.888A12.336,12.336,0,0,1,25.122,53.75Z"
    />
  </svg>
);

IconTwitter.defaultProps = defaultProps;

export default IconTwitter;
