import React, { FC } from 'react';

type Props = {
  additionalStyles?: { [index: string]: number | string };
  additionalClassNames?: string[];
};

const defaultProps: Props = {
  additionalStyles: {},
  additionalClassNames: [],
};

const IconCloseModal: FC<Props> = ({ additionalStyles, additionalClassNames }: Props) => (
  <svg
    className={`jc-icon ${additionalClassNames && additionalClassNames.join(' ')}`}
    style={additionalStyles && additionalStyles}
    data-icon-id="times"
    aria-hidden="true"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 30"
  >
    <path
      fill="currentColor"
      d="M15,2.813A12.188,12.188,0,1,1,2.812,15,12.187,12.187,0,0,1,15,2.813ZM15,30A15,15,0,1,0,0,15,15,15,0,0,0,15,30ZM10.254,10.254a1.4,1.4,0,0,0,0,1.986l2.754,2.754-2.754,2.754a1.4,1.4,0,0,0,1.986,1.986l2.754-2.754,2.754,2.754a1.4,1.4,0,0,0,1.986-1.986L16.98,14.994l2.754-2.754a1.4,1.4,0,0,0-1.986-1.986l-2.754,2.754L12.24,10.254A1.4,1.4,0,0,0,10.254,10.254Z"
    />
  </svg>
);

IconCloseModal.defaultProps = defaultProps;

export default IconCloseModal;
