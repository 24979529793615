import React, { FC } from 'react';

type Props = {
  additionalStyles?: { [index: string]: number | string };
  additionalClassNames?: string[];
};

const defaultProps: Props = {
  additionalStyles: {},
  additionalClassNames: [],
};

const iconCalendar: FC<Props> = ({ additionalStyles, additionalClassNames }: Props) => (
  <svg
    className={`jc-icon ${additionalClassNames && additionalClassNames.join(' ')}`}
    style={additionalStyles && additionalStyles}
    data-icon-id="calendar-line"
    data-testid="calendar-line"
    aria-hidden="true"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 12.25 14"
  >
    <path
      fill="currentColor"
      d="M11.922,4.375H.328A.328.328,0,0,1,0,4.047V3.063A1.312,1.312,0,0,1,1.313,1.75H2.625V.328A.328.328,0,0,1,2.953,0H4.047a.328.328,0,0,1,.328.328V1.75h3.5V.328A.328.328,0,0,1,8.2,0H9.3a.328.328,0,0,1,.328.328V1.75h1.313A1.312,1.312,0,0,1,12.25,3.063v.984A.328.328,0,0,1,11.922,4.375ZM.328,5.25H11.922a.328.328,0,0,1,.328.328v7.109A1.312,1.312,0,0,1,10.938,14H1.313A1.312,1.312,0,0,1,0,12.688V5.578A.328.328,0,0,1,.328,5.25ZM9.442,7.874,8.671,7.1a.328.328,0,0,0-.464,0l-2.9,2.876L4.051,8.7a.328.328,0,0,0-.464,0l-.776.77a.328.328,0,0,0,0,.464l2.259,2.277a.328.328,0,0,0,.464,0L9.44,8.338a.328.328,0,0,0,0-.464Z"
    />
  </svg>
);

iconCalendar.defaultProps = defaultProps;

export default iconCalendar;


