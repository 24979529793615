import React, { FC } from 'react';

type Props = {
  additionalStyles?: { [index: string]: number | string };
  additionalClassNames?: string[];
};

const defaultProps: Props = {
  additionalStyles: {},
  additionalClassNames: [],
};

const IconGoogle: FC<Props> = ({ additionalStyles, additionalClassNames }: Props) => (
  <svg
    className={`jc-icon ${additionalClassNames && additionalClassNames.join(' ')}`}
    style={additionalStyles && additionalStyles}
    data-icon-id="google"
    aria-hidden="true"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <path
      fill="currentColor"
      d="M507.86,262c1.54,159-100,250-247.74,250-141.38,0-256-114.62-256-256S118.74,0,260.12,0c69,0,127,25.29,171.66,67l-69.67,67C271,46,101.46,112.1,101.46,256c0,89.29,71.33,161.65,158.66,161.65,101.37,0,139.36-72.67,145.34-110.35H260.12V219.25H503.84a224.59,224.59,0,0,1,4,42.74Z"
    />
  </svg>
);

IconGoogle.defaultProps = defaultProps;

export default IconGoogle;
