import React, { FC } from 'react';

type Props = {
  additionalStyles?: { [index: string]: number | string };
  additionalClassNames?: string[];
};

const defaultProps: Props = {
  additionalStyles: {},
  additionalClassNames: [],
};

const IconClock: FC<Props> = ({ additionalStyles, additionalClassNames }: Props) => (
  <svg
    className={`jc-icon ${additionalClassNames && additionalClassNames.join(' ')}`}
    style={additionalStyles && additionalStyles}
    data-icon-id="clock"
    aria-hidden="true"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <path
      fill="currentColor"
      d="M256,512a258.35,258.35,0,0,1-51.59-5.2,254.64,254.64,0,0,1-91.54-38.52A256.6,256.6,0,0,1,20.12,355.65a254.93,254.93,0,0,1-14.92-48,258.57,258.57,0,0,1,0-103.19,254.64,254.64,0,0,1,38.52-91.54A256.6,256.6,0,0,1,156.35,20.12,254.9,254.9,0,0,1,204.4,5.21a258.32,258.32,0,0,1,103.19,0,254.64,254.64,0,0,1,91.54,38.52,256.6,256.6,0,0,1,92.75,112.63A254.7,254.7,0,0,1,506.8,204.4a258.57,258.57,0,0,1,0,103.19,254.64,254.64,0,0,1-38.52,91.54A255.46,255.46,0,0,1,256,512Zm0-451.9a26.7,26.7,0,0,0-26.72,26.72v187a26.7,26.7,0,0,0,4.19,14.37,26.7,26.7,0,0,0,5.8,8.65l80.36,80.36a26.71,26.71,0,1,0,37.78-37.77l-74.7-74.7V86.82A26.7,26.7,0,0,0,256,60.1Z"
    />
  </svg>
);

IconClock.defaultProps = defaultProps;

export default IconClock;
