import React, { FC } from 'react';

type Props = {
  additionalStyles?: { [index: string]: number | string };
  additionalClassNames?: string[];
};

const defaultProps: Props = {
  additionalStyles: {},
  additionalClassNames: [],
};

const IconBook: FC<Props> = ({ additionalStyles, additionalClassNames }: Props) => (
  <svg
    className={`jc-icon ${additionalClassNames && additionalClassNames.join(' ')}`}
    style={additionalStyles && additionalStyles}
    data-icon-id="chevron-down-circle"
    data-testid="chevron-down-circle"
    aria-hidden="true"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 14"
  >
    <path
      fill="currentColor"
      d="M0,3.677V14.5a.5.5,0,0,0,.686.464L5,13V1L.629,2.748A1,1,0,0,0,0,3.677ZM6,13l6,2V3L6,1ZM17.314,1.036,13,3V15l4.371-1.748A1,1,0,0,0,18,12.323V1.5A.5.5,0,0,0,17.314,1.036Z"
      transform="translate(0 -1)"
    />
  </svg>
);

IconBook.defaultProps = defaultProps;

export default IconBook;
