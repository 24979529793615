import React, { FC } from 'react';

type Props = {
  additionalStyles?: { [index: string]: number | string };
  additionalClassNames?: string[];
};

const defaultProps: Props = {
  additionalStyles: {},
  additionalClassNames: [],
};

const IconMapLocation: FC<Props> = ({ additionalStyles, additionalClassNames }: Props) => (
  <svg
    className={`jc-icon ${additionalClassNames && additionalClassNames.join(' ')}`}
    style={additionalStyles && additionalStyles}
    data-icon-id="map-location"
    data-testid="map-location"
    aria-hidden="true"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <path
      fill="currentColor"
      d="M362.67,119.28c0,48.54-65,135-93.51,169.87-6.85,9.33-19.47,9.33-26.32,0-28.53-34.85-93.51-121.33-93.51-169.87,2-140.62,211.32-140.63,213.34,0ZM256,147.73a35.56,35.56,0,0,0,35.56-35.56c-.8-46.74-70.33-46.73-71.12,0h0A35.56,35.56,0,0,0,256,147.73Zm122.31,26.71c.44-1.07.89-2.14,1.33-3.29L482.75,129.9A21.33,21.33,0,0,1,512,149.73V411.44a21.44,21.44,0,0,1-13.42,19.82l-128.8,51.47C373.12,471.13,361.18,176.14,378.31,174.44Zm-244.62,15c15.56,2.11,6.26,253.28,8.53,263.78l-113,45.24C15.24,503.26,0,493.75,0,478.64V215.93c0-8.71,5.31-17.34,13.41-19.83l108.9-43.55c2.14,12.62,6.49,25.15,11.38,36.89ZM291.38,323.93a749.83,749.83,0,0,0,49.95-67.65v227.6l-170.66-33.8V256.28c26.64,37,70.2,122.87,120.71,67.65Z"
    />
  </svg>
);

IconMapLocation.defaultProps = defaultProps;

export default IconMapLocation;
