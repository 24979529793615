import React, { FC } from 'react';

type Props = {
  additionalStyles?: { [index: string]: number | string };
  additionalClassNames?: string[];
};

const defaultProps: Props = {
  additionalStyles: {},
  additionalClassNames: [],
};

const IconTimes: FC<Props> = ({ additionalStyles, additionalClassNames }: Props) => (
  <svg
    className={`jc-icon ${additionalClassNames && additionalClassNames.join(' ')}`}
    style={additionalStyles && additionalStyles}
    data-icon-id="times"
    aria-hidden="true"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <path
      fill="currentColor"
      d="M346.3,402.78,256,312.45l-90.3,90.33a39.9,39.9,0,0,1-56.43,0h0a39.91,39.91,0,0,1,0-56.45L199.56,256l-90.3-90.33a39.91,39.91,0,0,1,56.43-56.46h0L256,199.55l90.3-90.33a39.9,39.9,0,0,1,56.43,0h0a39.91,39.91,0,0,1,0,56.45L312.44,256l90.3,90.33a39.91,39.91,0,1,1-56.44,56.45Z"
    />
  </svg>
);

IconTimes.defaultProps = defaultProps;

export default IconTimes;
