import React, { FC } from 'react';

type Props = {
  additionalStyles?: { [index: string]: number | string };
  additionalClassNames?: string[];
};

const defaultProps: Props = {
  additionalStyles: {},
  additionalClassNames: [],
};

const IconTimesCircle: FC<Props> = ({ additionalStyles, additionalClassNames }: Props) => (
  <svg
    className={`jc-icon ${additionalClassNames && additionalClassNames.join(' ')}`}
    style={additionalStyles && additionalStyles}
    data-icon-id="times"
    aria-hidden="true"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <path
      fill="currentColor"
      d="M256,512C74.18,513.1-51.46,323.41,20.12,156.35c89.08-207.75,382.7-207.75,471.76,0C563.45,323.42,437.83,513.1,256,512Zm0-199.54c7.25,5.84,96.26,100.38,103.5,99.09a39.94,39.94,0,0,0,52-52c1.51-7-93.6-96.6-99.07-103.53,5.85-7.25,100.35-96.28,99.07-103.53a39.94,39.94,0,0,0-52-52c-7-1.52-96.57,93.63-103.5,99.1-7.24-5.85-96.25-100.39-103.5-99.1a39.93,39.93,0,0,0-52,52c-1.52,7,93.6,96.6,99.06,103.53-5.84,7.25-100.35,96.28-99.06,103.53a39.93,39.93,0,0,0,52,52c7,1.51,96.58-93.63,103.5-99.1Z"
    />
  </svg>
);

IconTimesCircle.defaultProps = defaultProps;

export default IconTimesCircle;
