import React, { FC } from 'react';

type Props = {
  additionalStyles?: { [index: string]: number | string };
  additionalClassNames?: string[];
};

const defaultProps: Props = {
  additionalStyles: {},
  additionalClassNames: [],
};

const IconExternalLink: FC<Props> = ({ additionalStyles, additionalClassNames }: Props) => (
  <svg
    className={`jc-icon ${additionalClassNames && additionalClassNames.join(' ')}`}
    style={additionalStyles && additionalStyles}
    data-icon-id="external-link"
    data-testid="external-link"
    aria-hidden="true"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <path
      fill="currentColor"
      d="M432.32,320h-32a16,16,0,0,0-16,16V448h-320V128h192a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16h-208a48,48,0,0,0-48,48V464a48,48,0,0,0,48,48h352a48,48,0,0,0,48-48h0V336A16,16,0,0,0,432.32,320Zm56-320h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L183.65,271.37a24,24,0,0,0-.05,33.94l.05.06L206.32,328a24,24,0,0,0,34,.06l0-.06L435.6,133.32,471.32,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488.32,0Z"
    />
  </svg>
);

IconExternalLink.defaultProps = defaultProps;

export default IconExternalLink;
