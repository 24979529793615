import React, { FC } from 'react';

type Props = {
  additionalStyles?: { [index: string]: number | string };
  additionalClassNames?: string[];
};

const defaultProps: Props = {
  additionalStyles: {},
  additionalClassNames: [],
};

const IconWalking: FC<Props> = ({ additionalStyles, additionalClassNames }: Props) => (
  <svg
    className={`jc-icon ${additionalClassNames && additionalClassNames.join(' ')}`}
    style={additionalStyles && additionalStyles}
    data-icon-id="walking"
    data-testid="walking"
    aria-hidden="true"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <g fill="currentColor">
      <circle cx="325.55" cy="53.84" r="53.84" />
      <path d="M315.85,482.66c-8.53-21.22-8-40.68-21.07-74.43-8.56-20.8-87.77-66.41-96.17-113.29s8.18-89.4,8.18-89.4l-25.6,11s-13.74,40-22.64,55.18S136,288,115.35,283.35c-17.24-10.6-16.91-24.31-12.85-37.2,10.19-26.05,19.66-54.67,43.82-82.75,12.77-15.3,18.72-19.82,60.47-35.15,32.35-6.78,39.11-8.49,65-7.15,27.67,1.33,18.69,1.53,35,3.14,27.29,3.54,48.36,26.92,59,48.42,9.84,19.94,14,49.83,25.54,67.54.19.31,20.58,3.3,36.78,20.24A31.76,31.76,0,0,1,438.54,284a30.8,30.8,0,0,1-30.71,30.91c-12.91,0-39.55-8.35-60.23-31.54s-26.47-53.2-26.47-53.2l-28.91,69.57s59.62,56.84,66.61,68.79c5,8.5,24.78,87,26.59,108.51,0,9.07-4.53,32.08-26.59,34.47S328.14,508.25,315.85,482.66Z" />
      <path d="M174.25,321.76c7.15-15.74,18.24,8.66,32.77,26.8s32.18,9.23,31.59,22.54S215.43,421.29,191.67,446c-10,10.42-63.81,50.9-63.81,50.9s-30.43,11.41-44.61,0c-16-14.67-8.58-29.74-4.14-41.92s65.32-53.14,65.32-53.14S167.1,337.5,174.25,321.76Z" />
    </g>
  </svg>
);

IconWalking.defaultProps = defaultProps;

export default IconWalking;
