import React, { FC } from 'react';

type Props = {
  additionalStyles?: { [index: string]: number | string };
  additionalClassNames?: string[];
};

const defaultProps: Props = {
  additionalStyles: {},
  additionalClassNames: [],
};

const IconUser: FC<Props> = ({ additionalStyles, additionalClassNames }: Props) => (
  <svg
    className={`jc-icon ${additionalClassNames && additionalClassNames.join(' ')}`}
    style={additionalStyles && additionalStyles}
    data-icon-id="chevron-down-circle"
    data-testid="chevron-down-circle"
    aria-hidden="true"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 15.5 15.5"
  >
    <path
      fill="currentColor"
      d="M7.75.25A7.75,7.75,0,1,0,15.5,8,7.749,7.749,0,0,0,7.75.25Zm0,3A2.75,2.75,0,1,1,5,6,2.75,2.75,0,0,1,7.75,3.25ZM7.75,14a5.988,5.988,0,0,1-4.578-2.131A3.484,3.484,0,0,1,6.25,10a.765.765,0,0,1,.222.034,4.138,4.138,0,0,0,1.278.216,4.122,4.122,0,0,0,1.278-.216A.765.765,0,0,1,9.25,10a3.484,3.484,0,0,1,3.078,1.869A5.988,5.988,0,0,1,7.75,14Z"
      transform="translate(0 -0.25)"
    />
  </svg>
);

IconUser.defaultProps = defaultProps;

export default IconUser;
