import React, { FC } from 'react';

type Props = {
  additionalStyles?: { [index: string]: number | string };
  additionalClassNames?: string[];
};

const defaultProps: Props = {
  additionalStyles: {},
  additionalClassNames: [],
};

const IconChevronRight: FC<Props> = ({ additionalStyles, additionalClassNames }: Props) => (
  <svg
    className={`jc-icon ${additionalClassNames && additionalClassNames.join(' ')}`}
    style={additionalStyles && additionalStyles}
    data-icon-id="chevron-right"
    data-testid="chevron-right"
    aria-hidden="true"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <path
      fill="currentColor"
      d="M142.91,453.77a40,40,0,0,1,0-56.57L284,256.13,142.91,115.06c-36.5-37.68,18.71-93.17,56.57-56.56L364.35,223.37a40.55,40.55,0,0,1,0,65.53L199.48,453.77A40,40,0,0,1,142.91,453.77Z"
    />
  </svg>
);

IconChevronRight.defaultProps = defaultProps;

export default IconChevronRight;
