import React, { FC } from 'react';

type Props = {
  additionalStyles?: { [index: string]: number | string };
  additionalClassNames?: string[];
};

const defaultProps: Props = {
  additionalStyles: {},
  additionalClassNames: [],
};

const IconCircleDelete: FC<Props> = ({ additionalStyles, additionalClassNames }: Props) => (
  <svg
    className={`jc-icon ${additionalClassNames && additionalClassNames.join(' ')}`}
    style={additionalStyles && additionalStyles}
    data-icon-id="times"
    aria-hidden="true"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      d="M12,2.25A9.75,9.75,0,1,1,2.25,12,9.75,9.75,0,0,1,12,2.25ZM12,24A12,12,0,1,0,0,12,12,12,0,0,0,12,24ZM8.2,8.2a1.12,1.12,0,0,0,0,1.589l2.2,2.2L8.2,14.2a1.124,1.124,0,0,0,1.589,1.589l2.2-2.2,2.2,2.2A1.124,1.124,0,0,0,15.787,14.2l-2.2-2.2,2.2-2.2A1.124,1.124,0,0,0,14.2,8.2l-2.2,2.2-2.2-2.2A1.12,1.12,0,0,0,8.2,8.2Z"
    />
  </svg>
);

IconCircleDelete.defaultProps = defaultProps;

export default IconCircleDelete;

