import React, { FC } from 'react';

type Props = {
  additionalStyles?: { [index: string]: number | string };
  additionalClassNames?: string[];
};

const defaultProps: Props = {
  additionalStyles: {},
  additionalClassNames: [],
};

const IconHeart: FC<Props> = ({ additionalStyles, additionalClassNames }: Props) => (
  <svg
    className={`jc-icon ${additionalClassNames && additionalClassNames.join(' ')}`}
    style={additionalStyles && additionalStyles}
    data-icon-id="heart"
    data-testid="heart"
    aria-hidden="true"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <path
      fill="currentColor"
      d="M464.65,62.61C400.25,7.4,303.74,31.68,256,98.49,208.38,31.7,111.82,7.38,47.4,62.61-108.89,189,150.93,464.56,261,480,368.56,461.6,617.56,184.89,464.65,62.61Z"
    />
  </svg>
);

IconHeart.defaultProps = defaultProps;

export default IconHeart;
